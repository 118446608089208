.contactpage {
  background-color: #fff;
  .top-section {
    @media (max-width: 768px) {
    
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      background-attachment: fixed;
    }
  }
  .btn-trigger {
    display: none !important;
  }
  .circle-blur {
    background: #52bd95;
    filter: blur(300px);
    width: 20rem;
    height: 20rem;
    border-radius: 50%;
    top: -10rem;
    position: absolute;
    z-index: 1;
    @media (max-width: 768px) {
      width: 10rem;
      height: 10rem;
      top: -3rem;
    }
  }
}
