.base {
  background: red;
  display: inline-block;
  height: 55px;
  margin-left: 20px;
  margin-top: 55px;
  position: relative;
 
  width: 100px;
}
.base:before {
  border-bottom: 35px solid red;
  border-left: 30px solid transparent;
  border-right: 30px solid transparent;
  content: "";
  height: 0;
  left: 0;
  transform: rotate(90deg);
  position: absolute;
  bottom: -45px;
  width: 0;
}
.base:after {
  border-bottom: 75px solid red;
  border-left: 0px solid transparent;
  border-right: 80px solid transparent;
  content: "";
  height: 0;
  left: 0;
  //   transform: rotate(0deg);
  position: absolute;
  bottom: -35px;
  width: 0;
}

.steps-section {
  .header {
    font-size: 2rem;
    font-weight: 700;
    @media (max-width: 768px) {
      font-size: 1.5rem;
    }
  }
  background: url(../../assets/images/curve_bg.png);
  @media (max-width: 768px) {
    background: url(../../assets/images/curve_bg_mini.png);
 background-size:contain
    
  }
  background-position: center;
  background-position-y: 0px;
  background-size: cover;
  background-repeat: no-repeat !important;
}
