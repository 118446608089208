.footer-section {
  background-color: #161C28;
  color: #fff !important;
  overflow: hidden;
  background-image: url(/assets/images/footer_decorator.png);
  background-position: top right;
  background-repeat: no-repeat;
  // background-attachment:fixed;
  .copyright-text {
    font-family: var(--regular);
    font-size: 15px;
    @media (max-width: 1030px) {
      font-size: 11px;
    }
    @media (max-width: 768px) {
      font-size: 12px;
    }
  }
  .slice-box{
    position: absolute;
    z-index: 0;
    background-color: #232937;
    height: 35rem;
     width: 35rem;
     transform: rotate(45deg);
     right: -24%;
     margin-top: -20%;
  }
  .footer-title {
    font-weight: 700;
    font-size: 18px;
    color: #fff;
    margin-bottom: 30px;
    font-family: var(--bold);
    @media (max-width: 768px) {
      font-size: 12px;
      margin-bottom: 20px;
    }
  }
  .footerlist {
    font-family: var(--regular);
    list-style: none;
    font-size: 12px;
    li {
      margin-top: 20px;
      margin-bottom: 20px;
    }
    a {
      text-decoration: none;
      color: #fff;
      transition: .3s ease;
      :hover {
        margin-top:-5px;
      }
    }
    @media (max-width: 768px) {
      font-size: 10px;
    }
    padding: 0;
    margin: 0;
  }

}
