.contact-hero-section {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  // background-attachment: fixed;
  .img_girl {
    margin-left: -60px;
    @media (max-width: 768px) {
      margin-left: unset;
    }
  }
  .shadow-border {
    box-shadow: 0px 2px 5px 5px #3838381a;
    border-radius: 7px;
  }
  label,
  input,
  textarea {
    font-size: 15px;
  }
  textarea {
    min-height: 300px;
  }
  input {
    height: 45px;
  }
  .img-banner {
    @media (min-width: 768px) {
      right: -30px;
      position: relative;
    }
  }
  .shadow-none {
    box-shadow: none !important;
  }
  .link_text {
    color: #3734a9;
    font-size: 17px;
    text-decoration: none;
  }
  .video_btn {
    background: #22d497;
    box-shadow: 0px 100px 80px rgba(34, 212, 151, 0.07),
      0px 64.8148px 46.8519px rgba(34, 212, 151, 0.0531481),
      0px 38.5185px 25.4815px rgba(34, 212, 151, 0.0425185),
      0px 20px 13px rgba(34, 212, 151, 0.035),
      0px 8.14815px 6.51852px rgba(34, 212, 151, 0.0274815),
      0px 1.85185px 3.14815px rgba(34, 212, 151, 0.0168519);
    border-radius: 50%;
    width: 61.62px;
    height: 60px;
    align-items: center;
    display: flex;
    justify-content: center;
    @media (max-width: 768px) {
      width: 46.62px;
      height: 45px;
    }
    img {
      transition: 0.1s ease;
    }
  }
  .hero_bg {
    background: url(/assets/images/contact_hero.jpeg);
    background-repeat: no-repeat;
    background-position: center;
    @media (max-width: 768px) {
      background-position-x: left;
      background-size: cover;
    }
  }
}
