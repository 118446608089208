@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

:root {
  --imgWidthLg: 174px;
  --imgWidthMd: 236px;
  --imgWidthSm: 100px;
}

body {
  width: 100%;
  position: relative;
  overflow-x: hidden !important;
  margin: 0;
  background-color: #fff;
  scroll-behavior: smooth;
  font-family: "Poppins", sans-serif, -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
* {
  scroll-behavior: smooth;
}
.col-md-7 {
  @media (min-width: 768px) {
    width: 54.33333333% ;
  }
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.pad-alt {
  padding-left: 2rem;
  padding-right: 2rem;
  @media (max-width: 1030px) {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  @media (max-width: 500px) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
.text-aligner {
  &.text-aligner__start {
    text-align: start;
  }
  &.text-aligner__end {
    text-align: end;
  }
  @media (max-width: 768px) {
    text-align: center !important;
  }
}

.d-hide-sm {
  @media (max-width: 768px) {
    display: none !important;
  }
}
.d-hide-lg {
  @media (min-width: 768px) {
    display: none !important;
  }
}
.d-hide920 {
  @media (max-width: 920px) {
    display: none !important;
  }
}
.d-hide768 {
  @media (max-width: 768px) {
    display: none !important;
  }
}
.d-hide1024 {
  @media (max-width: 1024px) {
    display: none !important;
  }
}
.d-hide {
  display: none !important;
}
.d-show768 {
  display: none !important;

  @media (max-width: 768px) {
    display: block !important;
  }
}

.d-show1024 {
  display: none !important;

  @media (max-width: 1024px) {
    display: block !important;
  }
}
.green-text {
  color: #01966b !important;
}
.underline-none {
  text-decoration: none;
}
.w-80 {
  @media (min-width: 992px) {
    width: 70%;
  }
}
.w-60 {
  @media (min-width: 992px) {
    width: 35%;
  }
}
.w-70 {
  @media (min-width: 992px) {
    width: 65%;
  }
}
label {
  font-weight: 500;
  margin-bottom: 5px;
}
input,
textarea {
  outline: none !important;
  box-shadow: none !important;
}
.fs-14 {
  font-size: 0.875rem;
}
.fs-20 {
  font-size: 1.25rem;
  @media (max-width: 768px) {
    font-size: 1rem;
  }
}
.fs-50 {
  font-size: 3.225rem;
  @media (max-width: 768px) {
    font-size: 2rem;
  }
}
.fs-55 {
  font-size: 55px;
  @media (max-width: 768px) {
    font-size: 2rem;
  }
}
.fs-40 {
  font-size: 2.5rem;
  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
}
.fw-700 {
  font-weight: 700;
}
.fw-500 {
  font-weight: 500;
}
.fw-600 {
  font-weight: 600;
}
.line {
  border-bottom: 1px solid #e6e6e6;
  justify-content: space-between;
}
.white-text {
  color: #fff;
}
.w-50 {
  @media (max-width: 768px) {
    width: 100% !important;
  }
}
.blue-border {
  border: 2px dashed #2f71c8;
  border-radius: 11px;
  padding: 0px 5px;
}
.max-unset {
  max-width: unset !important;
}
.boxer {
  box-shadow: 0px 67px 80px rgba(55, 52, 169, 0.07),
    0px 43.4259px 46.8519px rgba(55, 52, 169, 0.0531481),
    0px 25.8074px 25.4815px rgba(55, 52, 169, 0.0425185),
    0px 13.4px 13px rgba(55, 52, 169, 0.035),
    0px 5.45926px 6.51852px rgba(55, 52, 169, 0.0274815),
    0px 1.24074px 3.14815px rgba(55, 52, 169, 0.0168519) !important;
}
.ml-neg {
  margin-left: -6rem;
  @media (max-width: 768px) {
    margin-left: 0;
  }
}
.ml-neg-sm {
  margin-left: -3rem;
}
.px-xs-0 {
  @media (max-width: 768px) {
    padding: 0;
  }
}
