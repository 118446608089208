.nav-section {
  .img-ctn{
    height: 110px;
    @media (max-width:768px) {
        height: 93px;
    }
  }
  &.open {
    position: absolute;
    height: 100vh;
    background-color: #E0F3ED;
    width: 100%;
    z-index: 999;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
  }
  ul.navlist-lg {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 400px;
  }
  .navlist-sm-ctn{
    display: none  ;
  }
  
  .h-80{
    height: 80vh !important;
  }
  .navlist-sm-ctn.open{
    display: flex;
  } 
  ul.navlist-sm{
    li{
      // text-align: center;
    }
    padding: 0px;
    width: 100%;
    list-style: none;
    text-align: center;
    min-height: 300px;
    display: flex !important;
    flex-direction: column;
    align-content: center;
    justify-content: space-between;
    a {
      text-decoration: none;
      color: #303030;
      font-weight: 500;
      font-size: 22px;
    }
  }
  .nav-toggler {
    display: flex;
    flex-direction: column;
    box-shadow: none;
    padding: 5px;
    
    height: 36px;
    img {
      padding-bottom: 8px;
    }
    img:nth-of-type(1) {
      transition: 0.2s ease;
    }
    img:nth-of-type(2) {
      transition: 0.25s ease;
    }
    img:nth-of-type(3) {
      transition: 0.2s ease;
    }
    img:nth-of-type(3) {
      padding-bottom: 0px !important;
    }
    &.open {
      img:nth-of-type(1) {
        padding-bottom: 8px !important;
        transform: rotate(45deg);
        transform-origin: 10% 70%;
      }
      img:nth-of-type(2) {
        padding-bottom: 8px !important;
        transform: translateX(3px);
        opacity: 0;
      }
      img:nth-of-type(3) {
        padding-bottom: 8px !important;
        transform: rotate(-45deg);
        transform-origin: 10% -30%;
      }
    }
  }
}
