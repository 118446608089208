.about-section {
  .header {
    font-size: 3rem;
    font-weight: 700;
    @media (max-width: 768px) {
      font-size: 1.8rem;
    }
  }

  .img_ctn {
    position: relative;
    display: inline-block;
    @media (max-width: 908px) {
      display: none;
    }
  }
  .img_ctn::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    margin-right: -35px;
    padding-bottom: 3px;
    width: 150px; /* adjust to your desired width */
    background: linear-gradient(
      90.4deg,
      rgba(255, 255, 255, 0.01) 44.12%,
      rgba(255, 255, 255, 0.826112) 47.8%,
      #ffffff 52.13%,
      #ffffff 88%
    );
    z-index: 0;
  }
  .img_ctn img {
    display: block;
    max-width: 100%;
    height: auto;
    z-index: 2;
  }
}
